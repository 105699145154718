* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: san;
}

@font-face {
    font-family: san;
    src: url(styles/fonts/SFNSDisplay-Regular.otf);
}
